// import React from 'react';

// const AboutDr = () => {
//     return (
//         <div className='about-dr py-5'>
//                 <div className="container">
//                     <h2 className='txt-primary sec-title'>About Me</h2>
//                     <div className='row gx-5 align-items-center py-5'>
//                         <div className="col-12 col-lg-4">
//                             <img src="./../../images/drimage.jpeg" alt="" />
//                         </div>

//                         <div className="col-12 col-lg-8">
//                             <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. Newton Kumar Varma</h3>
//                             <p className='sec-sub-title'>Associate Consultant</p>

//                             {/* <h5 className='degrees fw-bold'>Degrees</h5>
//                             <ol>
//                                 <li><strong>MBBS</strong>, Sher-e-Bangla Medical College</li>
//                                 <li><strong>Masters (MSc)</strong> in Clinical Gastroenterology, Memorial University of Newfoundland Canada</li>
//                                 <li><strong>FCPS (Surgery)</strong>, Bangladesh College of Physicians & Surgeons</li>
//                                 <li><strong>FRCS</strong>, Royal College of Surgeons, United Kingdom</li>
//                             </ol> */}
//                             <p>
                                
//                             Managing Director of Verma Nursing Home, Verma Dental Care, and Verma Medical
//                             Dr. Newton Kumar Verma is a highly skilled pharmacist from Jharkhand and the visionary behind the establishment of Verma Nursing Home, Verma Dental Care, and Verma Medical. With extensive experience in both pharmaceuticals and healthcare management, Dr. Verma is dedicated to providing exceptional patient care and overseeing all operations to ensure the highest quality of service.
//                             He is also a specialist in sugar treatment through Ayurveda, offering natural and effective solutions for managing diabetes and related health concerns. With his deep understanding of Ayurvedic principles, Dr. Verma provides holistic approaches to improve overall health and well-being, particularly in the management of chronic conditions like diabetes.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//     );
// };

// export default AboutDr;
import React from 'react';

const AboutDr = () => {
    return (
        <div className='about-dr py-5'>
            <div className="container">
                <h2 className='txt-primary sec-title'>About Our Doctors</h2>

                {/* Dr. Newton Kumar Verma */}
                <div className='row gx-5 align-items-center py-5'>
                    <div className="col-12 col-lg-4">
                        <img src="./../../images/drimage.jpeg" alt="Dr. Newton Kumar Verma" />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. NK Verma</h3>
                        <p className='sec-sub-title'>Managing Director | Verma Nursing Home, Verma Dental Care, Verma Medical</p>
                        <p>
                            Dr. NK Verma is a highly skilled pharmacist from Jharkhand and the visionary behind the establishment of Verma Nursing Home, Verma Dental Care, and Verma Medical. With extensive experience in both pharmaceuticals and healthcare management, Dr. Verma is dedicated to providing exceptional patient care and overseeing all operations to ensure the highest quality of service. He is also a specialist in sugar treatment through Ayurveda, offering natural and effective solutions for managing diabetes and related health concerns. With his deep understanding of Ayurvedic principles, Dr. Verma provides holistic approaches to improve overall health and well-being, particularly in the management of chronic conditions like diabetes.
                        </p>
                    </div>
                </div>

                {/* Dr. Subodh Kumar */}
                <div className='row gx-5 align-items-center py-5'>
                    <div className="col-12 col-lg-4">
                        <img src="./../../images/sk.jpeg" alt="Dr. Subodh Kumar" />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. Subodh Kumar</h3>
                        <p className='sec-sub-title'>MBBS (AM), New Delhi | CCH Community Health & General Physician</p>
                        <p>
                            Dr. Subodh Kumar is a well-respected General Physician and expert in Community Health. He holds an MBBS degree and has trained in the Community Health field in New Delhi. His expertise lies in diagnosing and treating a wide range of medical conditions, with a focus on providing holistic healthcare to patients.
                        </p>
                    </div>
                </div>

                {/* Dr. Manish Kr. Prasad */}
                <div className='row gx-5 align-items-center py-5'>
                    <div className="col-12 col-lg-4">
                        <img src="./../../images/drmanish.jpeg" alt="Dr. Manish Kr. Prasad" />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. Manish Kr. Prasad</h3>
                        <p className='sec-sub-title'>MBBS | Medical Officer at CHC Akbarpur | General Physician</p>
                        <p>
                            Dr. Manish Kumar Prasad is a General Physician with an MBBS degree and serves as a Medical Officer at CHC Akbarpur. His clinical experience includes treating patients for various illnesses and chronic conditions. Dr. Prasad is committed to offering comprehensive and personalized care to meet the needs of each patient.
                        </p>
                    </div>
                </div>

                {/* Dr. Dhananjay Kumar */}
                <div className='row gx-5 align-items-center py-5'>
                    <div className="col-12 col-lg-4">
                        <img src="./../../images/drnone.jpeg" alt="Dr. Dhananjay Kumar" />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. Dhananjay Kumar</h3>
                        <p className='sec-sub-title'>BDS (Patna), MDS (Patna) | Dental Implantologist (Lucknow) | Mastership in Laser Dentistry (Lucknow)</p>
                        <p>
                            Dr. Dhananjay Kumar is an experienced Dental Implantologist and Laser Dentistry Specialist. He completed his BDS and MDS degrees from Patna, followed by advanced training in Dental Implants and Laser Dentistry from Lucknow. Dr. Kumar is skilled in providing innovative dental treatments, including implants, laser therapies, and advanced cosmetic dental procedures.
                        </p>
                    </div>
                </div>

                <div className='row gx-5 align-items-center py-5'>
    <div className="col-12 col-lg-4">
        <img src="./../../images/drgagan.jpeg" alt="Dr. Gagan Raj" />
    </div>
    <div className="col-12 col-lg-8">
        <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. Gagan Raj</h3>
        <p className='sec-sub-title'>BDS (New Delhi), MDS (Patna) | Dental Implantologist (Lucknow)</p>
        <p>
            Dr. Gagan Raj is a highly skilled Dental Implantologist with a comprehensive background in advanced dental practices. He completed his BDS degree in New Delhi and went on to achieve his MDS from Patna. Furthering his expertise, Dr. Raj received specialized training in Dental Implants in Lucknow. He is dedicated to providing state-of-the-art dental treatments, focusing on implants, restorative techniques, and personalized patient care.
        </p>
    </div>
</div>


                {/* Dr. Uday Kumar */}
                <div className='row gx-5 align-items-center py-5'>
                    <div className="col-12 col-lg-4">
                        <img src="./../../images/drnone.jpeg" alt="Dr. Uday Kumar" />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h3 className='fw-bold txt-primary mt-lg-0 mt-4'>Dr. Uday Kumar</h3>
                        <p className='sec-sub-title'>MBBS, MD | General Physician</p>
                        <p>
                            Dr. Uday Kumar is a skilled and experienced General Physician with an MBBS and MD degree. He is dedicated to providing comprehensive healthcare, with a focus on accurate diagnosis and effective treatment for a wide range of medical conditions. Dr. Kumar’s expertise lies in managing chronic diseases, preventive care, and promoting overall wellness. His patient-centered approach ensures that each patient receives personalized attention, aiming to improve their health outcomes through evidence-based medical practices.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AboutDr;
