import React from 'react';
import { Link } from 'react-router-dom';

const Appoinment = () => {
    return (
        <div id="appointment" className='mb-5'> 
                <div className="container">
                    <h2 className='txt-primary sec-title mb-5'>Book any Service</h2>
                    <div className="row gx-5">
                        <div className="col-lg-4">
                            <div className="package">
                                <div className="pack-img">
                                    <img src="images/pack1.jpeg" alt="" />
                                </div>
                                <div className="pack-title">CONSULT ABOUT Surgery</div>
                                <div className="pack-desc"> 
                                Are you in confusion about what to do, or do you need any surgery or not? Let's discuss your issue and find out if you really need surgery. I will help you evaluate your condition and guide you on whether surgery is the best option or if alternative treatments can be considered.
                                </div>
                                <div className="pack-price">Rs. 2000</div>
                                <Link className="btn btn-primary" to='/checkout'> Book now </Link>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package">
                                <div className="pack-img">
                                    <img src="images/pack2.jpeg" alt="" />
                                </div>
                                <div className="pack-title">Emergency Care Consultation</div>
                                <div className="pack-desc">
                                Are you unsure about the severity of your health condition, or is an emergency situation arising? Don’t worry; I am here to help you understand if immediate medical intervention is needed. Whether it’s an injury, sudden illness, or any urgent health concern, let’s discuss and evaluate your situation to ensure you get the right care at the right time.
                                </div>
                                <div className="pack-price">Rs. 4000</div>
                                <Link className="btn btn-primary" to='/checkout'> Book now </Link>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package">
                                <div className="pack-img">
                                    <img src="images/pack3.jpeg" alt="" />
                                </div>
                                <div className="pack-title">OPD Consultation</div>
                                <div className="pack-desc">
                                Visit our OPD for consultations on various health concerns. Whether it's minor ailments or ongoing medical issues, I will provide a thorough assessment and treatment plan tailored to your needs.
                                </div>
                                <div className="pack-price">Rs. 500</div>
                                <Link className="btn btn-primary" to='/checkout'> Book now </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Appoinment;