import React from 'react';

const Hero = () => {
    return (
        <div className='hero py-5'>
            <div className="container py-5">
                <h1 className='txt-primary'>Verma Nursing Home</h1>
                <p className='sub-title'><b>Your Trusted Partner in Health</b></p>

                <p className='header-body'>
            Verma Nursing Home is committed to delivering exceptional, personalized healthcare with a full range of services. Under the guidance of <b>Dr. NK Verma</b>, a skilled <b>pharmacist</b> and specialist in <b>Ayurvedic diabetes treatment</b>, we offer a unique blend of modern and traditional medicine for comprehensive care.

        Our key services include <b>Surgical Consultations</b> for guidance on whether surgery is necessary, <b>Emergency Care Consultations</b> for urgent health needs, and <b>General OPD consultations </b>for a thorough assessment of various health concerns. Dr. Verma’s expertise in Ayurvedic sugar management also provides natural and effective solutions for diabetes.

        For quality, compassion, and expertise in every consultation, <b>book your appointment</b> with Verma Nursing Home today and start your journey to better health.
  </p>
                <a href='#appointment' className='btn btn-primary mt-2'>Book Appointment</a>
            </div>
        </div>
    );
};

export default Hero;