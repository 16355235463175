import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className='py-5 text-white'>
            <div className="container">
                <div className="row text-center justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <div className="brand">
                            Verma Nursing Home
                            
                        </div>
                        <p>For any queries, visit this email: <a href="veramnurshinghome@gmail.com" className="text-white">vermanurshinghome@gmail.com</a></p><br />
                        <div className="col-lg-6">
                        <div className="map-container">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.253359458447!2d85.52089267514837!3d24.75250087799994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f301c8344b2e6b%3A0x85446b118aa800cf!2sVerma%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1731580239796!5m2!1sen!2sin"
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Verma Nursing Home Location"
                            ></iframe>
                        </div>
                    </div>
                    <p>©2024 | Verma Nursing Home | Website Developed by :- 
                     <a 
                        href="https://github.com/johnsonofficial" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-white">
                        @johnson raj
                    </a>
                </p>
                    </div>
                    
                    
                </div>
            </div>
        </footer>
    );
};

export default Footer;
