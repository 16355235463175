import React from 'react';

const About = () => {
    return (
        <div>
            <div className="container py-5">
                <h1 className='txt-primary'><b>Facilities Provided by Verma Nursing Home </b> </h1>
                <p>
                    Welcome to <b>Verma Nursing Home</b>, a trusted healthcare facility located at <b>Fatehpur More in Nawada, Bihar.</b> We offer a wide range of specialized treatments tailored to meet the unique health needs of our patients. Our mission is to provide comprehensive, patient-centered care in a comfortable and trustworthy environment.
                </p>

                <div>
                    <b>Emergency Services</b>
                    <p>Available 24/7 for urgent medical needs, ensuring immediate response and treatment during critical situations.</p>
                </div>
                <div>
                    <b>Body Checkups</b>
                    <p>Comprehensive health checkups for early detection of medical issues and preventive care, promoting optimal health.</p>
                </div>
                <div>
                    <b>Dental Care</b>
                    <p>Specialized dental services including preventive care, fillings, extractions, root canals, and other dental treatments to maintain oral health.</p>
                </div>
                <div>
                    <b>ICU Room</b>
                    <p>Equipped with advanced life-support systems for critically ill patients requiring continuous monitoring and medical intervention.</p>
                </div>
                <div>
                    <b>General Ward</b>
                    <p>Comfortable and spacious rooms for patients needing routine care or post-surgery recovery, ensuring a clean and safe environment for healing.</p>
                </div>
                <div>
                    <b>OPD (Outpatient Department)</b>
                    <p>Well-equipped for consultations, minor treatments, and follow-up care, ensuring patient convenience.</p>
                </div>
                <div>
                    <b>First Aid</b>
                    <p>Immediate care for minor injuries and health concerns, providing basic treatment and stabilization before further intervention if required.</p>
                </div>
                <div>
                    <b>Critical Care</b>
                    <p>Specialized care for patients with severe conditions, providing multi-disciplinary support from experienced doctors and medical staff.</p>
                </div>
                <div>
                    <b>General Surgery</b>
                    <p>Offering a wide range of surgical services, from minor procedures to major surgeries, with a focus on patient safety and recovery.</p>
                </div>
                <div>
                    <b>Medicine</b>
                    <p>Comprehensive treatment for a variety of conditions, emphasizing evidence-based medical practices to ensure optimal care for patients.</p>
                </div>
                <div>
                    <b>Ayurvedic Medicine</b>
                    <p>Holistic treatments using Ayurvedic practices, offering natural healing options and preventive care for patients seeking an alternative approach.</p>
                </div>
            </div>
        </div>
    );
};

export default About;